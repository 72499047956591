import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { rhythm, scale } from '../utils/typography';

function FluidImage({ image, description }) {
  if (!image?.childImageSharp?.gatsbyImageData) {
    return null;
  }

  return (
    <GatsbyImage
      alt={description}
      image={image.childImageSharp.gatsbyImageData}
    />
  );
}

function ImageCredit({ imageCredit }) {
  const user = imageCredit?.unsplash?.user;

  if (!user) {
    return null;
  }

  const { id, name } = user;

  const url = `https://unsplash.com/${encodeURIComponent(
    id
  )}?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText`;

  return (
    <figcaption>
      Photo by <a href={url}>{name}</a> on <a href={url}>Unsplash</a>
    </figcaption>
  );
}

function CoverImage({ image, imageCredit }) {
  if (!image) {
    return null;
  }

  return (
    <figure className="cover-image">
      <FluidImage
        image={image}
        description={imageCredit?.description}
        loading="eager"
      />
      <ImageCredit imageCredit={imageCredit} />
    </figure>
  );
}

const ordinalValues = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
  'twentieth',
];

function toOrdinal(value) {
  const result = ordinalValues[value];
  if (!result) {
    throw new Error('finish the implementation');
  }

  return result;
}

function InlinePreviousPosts({ posts }) {
  if (posts.length === 0) {
    return null;
  }

  if (posts.length === 1) {
    return (
      <>
        The first one is <a href={posts[0].slug}>{posts[0].title}</a>; you might
        want to start there.
      </>
    );
  }

  if (posts.length === 2) {
    return (
      <>
        The first two are <a href={posts[0].slug}>{posts[0].title}</a> and{' '}
        <a href={posts[1].slug}>{posts[1].title}</a>.
      </>
    );
  }

  return <>The earlier posts in the series are:</>;
}

function BlockPreviousPosts({ posts }) {
  if (posts.length < 3) {
    return null;
  }

  return (
    <ol>
      {posts.map(({ title, slug }) => (
        <li key={slug}>
          <i>
            <a href={slug}>{title}</a>
          </i>
        </li>
      ))}
    </ol>
  );
}

function SeriesHeader({ series, slug }) {
  if (!series) {
    return null;
  }

  const { headingNote } = series.parent.frontmatter;
  const orderedPosts = series.blogPosts
    .sort((left, right) => left.date.localeCompare(right.date))
    .map(({ slug, parent }) => ({
      slug,
      title: parent.frontmatter?.title,
    }));

  const currentIndex = orderedPosts.findIndex(x => x.slug === slug);

  if (currentIndex === 0) {
    return null;
  }

  const previousPosts = orderedPosts.slice(0, currentIndex);

  return (
    <section>
      <p>
        <i>
          Note: {headingNote.replace('%ORDINAL%', toOrdinal(currentIndex + 1))}{' '}
          <InlinePreviousPosts posts={previousPosts} />
        </i>
      </p>
      <BlockPreviousPosts posts={previousPosts} />
    </section>
  );
}

function SeriesFooter({ series, slug }) {
  if (!series) {
    return null;
  }

  const { incomplete } = series.parent.frontmatter;
  const orderedPosts = series.blogPosts
    .sort((left, right) => left.date.localeCompare(right.date))
    .map(({ slug, parent }) => ({
      slug,
      title: parent.frontmatter?.title,
    }));

  const currentIndex = orderedPosts.findIndex(x => x.slug === slug);

  if (currentIndex === orderedPosts.length - 1) {
    return null;
  }

  const previousPosts = orderedPosts.slice(0, currentIndex);
  const followingPosts = orderedPosts.slice(currentIndex + 1);

  return (
    <section>
      <hr />
      <p>Looking for the rest of the series?</p>
      <ol>
        {previousPosts.map(({ title, slug }) => (
          <li key={slug}>
            <a href={slug}>{title}</a>
          </li>
        ))}
        <li>{orderedPosts[currentIndex].title}</li>
        {followingPosts.map(({ title, slug }) => (
          <li key={slug}>
            <a href={slug}>{title}</a>
          </li>
        ))}
        {!!incomplete && <li>&hellip;Coming eventually</li>}
      </ol>
    </section>
  );
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const { date, coverImage, parent: post, series, slug } = data.blogPost;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  const { frontmatter, excerpt, html } = post;
  const { title, description, imageCredit } = frontmatter;

  const [bodyHtml, footnoteHtml] = html.split(/(?=<div class="footnotes">)/);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={title}
        description={description || excerpt}
        coverImage={coverImage}
        imageAlt={imageCredit?.description}
        ogType="article"
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {title}
          </h1>
          <p
            style={{
              ...scale(-2 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {date}
          </p>
          <CoverImage image={coverImage} imageCredit={imageCredit} />
        </header>
        <SeriesHeader series={series} slug={slug} />
        <section dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        <SeriesFooter series={series} slug={slug} />
        {footnoteHtml && (
          <section dangerouslySetInnerHTML={{ __html: footnoteHtml }} />
        )}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.slug} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    blogPost(slug: { eq: $slug }) {
      slug
      date(formatString: "MMMM DD, YYYY")
      coverImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 750)
          fullWidthImageData: gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
      parent {
        ... on MarkdownRemark {
          html
          excerpt(pruneLength: 240)
          frontmatter {
            title
            imageCredit: coverImage {
              description
              unsplash {
                user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      series {
        slug
        blogPosts {
          slug
          date
          parent {
            ... on MarkdownRemark {
              frontmatter {
                title
              }
            }
          }
        }
        parent {
          ... on MarkdownRemark {
            frontmatter {
              title
              headingNote
              incomplete
            }
          }
        }
      }
    }
  }
`;
